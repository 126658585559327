import { BackpackIcon, DashboardIcon } from "@radix-ui/react-icons";
import { useLoaderData, useLocation, useNavigate, useSearchParams } from "@remix-run/react";
import { useMemo, useRef } from "react";
import { Configure, InstantSearch } from "react-instantsearch";
import { Container, Flex } from "sparta";
import { PostHits } from "~/components/search/PostHits";
import { ExportProvider } from "~/context/ExportContext";
import { RefinementMultiSelect } from "~/routes/search_v2/components/RefinementMultiSelect";
import { homeClient } from "~/sdk/qs3/typesenseClient";
import { searchStateToUrl, searchStateToUrlHomePage, urlToSearchState } from "~/utils/search";
import AdvertisementComponent from "../../../../components/layout/Advertisement";

export default function GridFeed() {
  const { url, advertisements } = useLoaderData<any>();
  const navigate = useNavigate();
  const location = useLocation();
  const searchState = useMemo(() => urlToSearchState(location), [location.search]);
  const debouncedSetStateRef = useRef(null);

  const [searchParams] = useSearchParams();
  const filters = {
    category: searchParams.get("category"),
    agency: searchParams.get("agency"),
  };

  /**
   * Every time the search state changes, we update the URL
   * with the new search state. We debounce the URL update
   * to avoid updating the URL too often.
   *
   * @param updatedSearchState The updated ui state from the InstanSearch component
   */
  function onSearchStateChange(updatedSearchState) {
    clearTimeout(debouncedSetStateRef.current);

    debouncedSetStateRef.current = setTimeout(() => {
      const url = searchStateToUrlHomePage(location, updatedSearchState.uiState);
      if (url !== window.location.href) {
        navigate(searchStateToUrlHomePage(location, updatedSearchState.uiState), {
          replace: true,
        });
      }
    }, 250);

    updatedSearchState.setUiState(updatedSearchState.uiState);
  }

  const platformFromUrl = location.pathname.split("/")[1];
  // Utility function to format the platform string
  const formatPlatform = (platform) => {
    if (!platform) return "";

    // Specific mappings
    const mappings = {
      fedciv: "FedCiv",
      health: "Health",
      defense: "Defense",
      // Add other specific mappings here if needed
    };

    // Check for specific cases, else capitalize first letter
    return mappings[platform.toLowerCase()] || platform.charAt(0).toUpperCase() + platform.slice(1).toLowerCase();
  };

  // Format the platform value
  const formattedPlatform = formatPlatform(platformFromUrl);

  return (
    <InstantSearch
      searchClient={homeClient}
      indexName="posts"
      initialUiState={searchState as any}
      onStateChange={onSearchStateChange}
      future={{
        preserveSharedStateOnUnmount: true,
      }}
      stalledSearchDelay={100}
    >
      <Configure
        facetsRefinements={{
          platforms: platformFromUrl ? [platformFromUrl] : [],
          ...(filters.category ? { postType: [filters.category] } : {}),
          ...(filters.agency ? { agency: [filters.agency] } : {}),
        }}
      />
      <ExportProvider initialUrl={url}>
        <Container>
          {advertisements.length > 0 ? <AdvertisementComponent advertisement={advertisements[0]} /> : null}
          <Flex direction="column" gap="4">
            <Flex>
              <RefinementMultiSelect
                attribute="agency"
                sortBy={["name:asc"]}
                searchablePlaceholder="Agencies"
                limit={200}
                leftIcon={<BackpackIcon />}
              />
              <RefinementMultiSelect
                attribute="postType"
                searchable
                searchablePlaceholder="Categories"
                leftIcon={<DashboardIcon />}
              />
            </Flex>
            <PostHits />
          </Flex>
        </Container>
      </ExportProvider>
    </InstantSearch>
  );
}
